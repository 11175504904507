import { UnionOrderType } from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import { initialStateList as initialState } from '../../orders/resources'

const orderListSlice = createSlice({
    name: 'orderList',
    initialState,
    reducers: {
        getOrderList: (state) => {
            return state
        },
        updateOrderList: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateOrderById: (state, action) => {
            state.data[action.payload.id] = action.payload
        },
        updateOrderByOrderId: (state, action: PayloadAction<Partial<UnionOrderType>>) => {
            state.data = state.data?.map((order) =>
                order.id === action.payload.id
                    ? ({ ...order, ...action.payload } as UnionOrderType)
                    : (order as UnionOrderType),
            )
        },
        addOrderById: (state, action) => {
            state.data.push(action.payload)
        },
        deleteOrderById: (state, action: PayloadAction<number>) => {
            if (state.data.length) {
                state.data = state.data.filter((v) => v.id !== action.payload)
            }
        },
        dropOrderList: () => {
            return { ...initialState }
        },
        updateErrorOrderList: (state, action) => {
            state.error = action.payload
        },
        updateLoadingOrderList: (state, action) => {
            state.isLoading = action.payload
        },
    },
})

export const loadDfasOrders = createAction('dfaOrders/loadDfasOrders')
export const loadOrdersByDfaId = createAction('dfaOrders/loadOrdersByDfaId')
export const loadAllOrdersByUser = createAction<string>('dfaOrders/loadAllOrdersByUser')
export const {
    updateOrderList,
    getOrderList,
    dropOrderList,
    updateOrderById,
    addOrderById,
    updateErrorOrderList,
    updateLoadingOrderList,
    updateOrderByOrderId,
    deleteOrderById,
} = orderListSlice.actions
export default orderListSlice.reducer
