import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import {
    ExternalRateIndex,
    IEmissionOrder,
    earlyRepaymentsToOrders,
    unionTypeBackToFront,
} from '@dltru/dfa-models'
import { sortByFieldName } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { loadDfaRates } from '@store/dfa/rates'
import { dfaRatesSelector } from '@store/dfa/rates/selectors'

import api from '@services/api'

import { backToFrontArray } from '../mapper'

import { loadAllOrdersByUser, loadDfasOrders, loadOrdersByDfaId, updateOrderList } from './index'

function* handleLoadOrders() {
    try {
        yield* put(updateOrderList({ error: '', isLoading: true }))

        const { data, error } = yield api.lib.getDfaOrdersService()
        if (error) {
            throw error
        }

        const rateData = yield* select(dfaRatesSelector.selectDfaRates)

        if (!rateData) {
            throw new Error()
        }

        const ordersDataItems = (data?.items || []).map((item) => {
            if (item.price_per_dfa) {
                return item
            }

            const rate = rateData[item.price_source || ExternalRateIndex.GLDRUB_TOM]
            const price = rate + (rate / 100) * (item.asset_investment_spread / 1000)

            return {
                ...item,
                amount_dfa: Math.floor(item.order_price / price),
                price_per_dfa: price,
                total_price: item.order_price,
            }
        })

        yield* put(updateOrderList({ isLoading: false, data: ordersDataItems }))
    } catch (error) {
        yield* put(updateOrderList({ error, isLoading: false, data: [] }))
    }
}

function* handleLoadOrdersById() {
    try {
        yield* put(loadDfaRates())
        const userId = yield* select(authSelector.selectUserUid)
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        yield* put(updateOrderList({ error: '', isLoading: true }))

        if (!dfa.id) {
            throw new Error('missed dfa id')
        }
        const { data, error } = yield api.lib.getDfaOrdersByIdService<IEmissionOrder[]>(dfa.id)

        if (error) {
            throw error
        }

        const rateData = yield* select(dfaRatesSelector.selectDfaRates)
        if (!rateData) {
            throw new Error()
        }

        const ordersDataItems = (data?.items || []).map((item) => {
            if (item.price_per_dfa) {
                return item
            }

            const rate = rateData[dfa.price_source || ExternalRateIndex.GLDRUB_TOM]
            const price = Math.trunc(rate + (rate / 100) * (item.asset_investment_spread / 1000))

            return {
                ...item,
                amount_dfa: Math.trunc(item.order_price / price),
                price_per_dfa: price,
                total_price: item.order_price,
            }
        })

        const _data = ordersDataItems.filter(
            (order) => order.emitter_id === userId || order.user_id === userId,
        )

        yield* put(updateOrderList({ isLoading: false, data: backToFrontArray(_data) }))
    } catch (error) {
        yield* put(updateOrderList({ error, isLoading: false, data: [] }))
    }
}

function* handleLoadAllOrdersByUser({ payload }: ReturnType<typeof loadAllOrdersByUser>) {
    try {
        yield* put(updateOrderList({ error: '', isLoading: true }))
        const query = {
            user_id: payload,
        }

        const { data: ordersData, error: ordersError } = yield api.lib.getDfaOrders(query)
        if (ordersError) {
            throw ordersError
        }

        const rateData = yield* select(dfaRatesSelector.selectDfaRates)

        if (!rateData) {
            throw ordersError
        }

        const { data: erData, error: erError } = yield* call(
            api.lib.getEarlyRepaymentApplicationsService,
            { user_uuid: payload, limit: 9000 },
        )

        if (erError) {
            throw erError
        }

        const ordersDataItems = (ordersData?.items || []).map((item) => {
            if (item.price_per_dfa) {
                return item
            }

            const rate = rateData[item.price_source || ExternalRateIndex.GLDRUB_TOM]
            const price = rate + (rate / 100) * (item.asset_investment_spread / 1000)

            return {
                ...item,
                amount_dfa: Math.floor(item.order_price / price),
                price_per_dfa: price,
                total_price: item.order_price,
            }
        })
        const orders = [...ordersDataItems, ...earlyRepaymentsToOrders(erData?.items)]

        const { data: secondaryOrdersData, error: secondaryOrdersError } =
            yield api.lib.getSecondaryDfaOrders(query)
        if (secondaryOrdersError) {
            throw secondaryOrdersError
        }

        yield* put(
            updateOrderList({
                isLoading: false,
                data: [...orders, ...(secondaryOrdersData?.items || [])]
                    .sort(sortByFieldName('created_at', 'desc'))
                    .map(unionTypeBackToFront),
            }),
        )
    } catch (error) {
        yield* put(updateOrderList({ error, isLoading: false, data: [] }))
    }
}

export function* watchOrders(): Generator<StrictEffect> {
    yield* takeLatest(loadDfasOrders.type, handleLoadOrders)
    yield* takeLatest(loadOrdersByDfaId.type, handleLoadOrdersById)
    yield* takeLatest(loadAllOrdersByUser.type, handleLoadAllOrdersByUser)
}
