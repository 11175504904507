import { ErrorCodes, closeMessage, errorTranslates, openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, delay, put, select, takeLatest } from 'typed-redux-saga'

import { getCalculateFeeHelper, signCustomTransaction } from '@store/helper'

import api from '@services/api'

import { updateOrderByOrderId } from '../list/index'
import { backToFront } from '../mapper'
import {
    acceptedByEmitter,
    getBuyFee,
    postOrder,
    postOrderPrefly,
    refusedByEmitter,
    revokeOrder,
    setDfaDetailsFee,
    updateOrderDetails,
} from './index'
import { clearDataTransactionDetails, setIsLoadingTransactionDetails, setItemsTransactionDetails } from '@store/sign'
import { transactionDetailsSelectors } from '@store/sign/selectors'

function* handlePostOrderPrefly({ payload }: ReturnType<typeof postOrder>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
        const { data, error } = yield* call(api.lib.storeEmissionOrderServiceV4, payload)
        
        if (error || data?.error) {
            throw error || data?.error
        }
        
        yield * put(setItemsTransactionDetails(data));
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
    }
      
}

function* handlePostOrder({ payload }: ReturnType<typeof postOrder>) {
    try {
        openMessage({
            type: 'loading',
            key: 'loadingPostOrder',
            message: 'Размещение заявки',
        })

        yield* put(updateOrderDetails({ error: '', isLoading: true }))
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.item.hash) {
            const dataForSign = {
              ...payload,
              transaction: data.item,
            }
            yield* signCustomTransaction(
              payload.skid,
              dataForSign,
              api.lib.putEmissionOrderServiceV4,
            )
          }
        closeMessage('loadingPostOrder')
        yield* put(clearDataTransactionDetails())
        openMessage({
            type: 'success',
            message: 'Заявка размещена',
        })
        yield* put(updateOrderDetails({ error: '', isLoading: false }))
    } catch (error) {
        closeMessage('loadingPostOrder')
        const description = errorTranslates[error as ErrorCodes]
        openMessage({
            type: 'error',
            message: `Заявка отклонена${description ? `: ${description}` : ''}`,
        })
        yield* put(updateOrderDetails({ error: 'order error', isLoading: false }))
    }
}

function* handleRevokeOrder({ payload }: ReturnType<typeof revokeOrder>) {
    try {
        yield* put(updateOrderDetails({ error: '', isLoading: true }))

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, error } = yield api.lib.revokeOrderService(payload)
        if (error) {
            // eslint-disable-next-line no-console
            console.log('data: ', data) // не отрабатывает исключение
            throw error
        }

        yield* put(updateOrderDetails({ error: '', isLoading: false }))
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
        yield* put(updateOrderDetails({ error: 'order error', isLoading: false }))
    }
}

function* handleAcceptedByEmitter({ payload }: ReturnType<typeof acceptedByEmitter>) {
    try {
        yield* put(updateOrderDetails({ error: '', isLoading: true }))

        const { error } = yield api.lib.acceptedByEmitter(payload.uuid)
        if (error) {
            throw error
        }
        const { data } = yield* call(api.lib.getDfaOrderById, payload.id)

        if (data?.item) {
            yield* put(updateOrderByOrderId({ id: data.item.id, status: data.item.status }))
        }
        yield* put(updateOrderDetails({ error: '', isLoading: false }))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при согласовании',
        })
        yield* put(updateOrderDetails({ error: 'accepted error', isLoading: false }))
    }
}
function* handleRefusedByEmitter({ payload }: ReturnType<typeof refusedByEmitter>) {
    try {
        yield* put(updateOrderDetails({ error: '', isLoading: true }))

        const { error } = yield api.lib.refusedByEmitter(payload.uuid)
        if (error) {
            throw error
        }

        yield delay(700) // костыль
        const { data } = yield api.lib.getDfaOrderById(payload.id)

        if (data.item) {
            yield* put(updateOrderByOrderId(backToFront(data.item)))
        }

        yield* put(updateOrderDetails({ error: '', isLoading: false }))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при отклонении',
        })
        yield* put(updateOrderDetails({ error: 'refused error', isLoading: false }))
    }
}

function* handleLoadBuyFee({ payload }: ReturnType<typeof getBuyFee>) {
    try {
        const data = yield* call(getCalculateFeeHelper, payload)

        yield* put(setDfaDetailsFee(data.item.fee_amount))
    } catch (error) {
        yield* put(setDfaDetailsFee(undefined))
    }
}

export function* watchOrderDetails(): Generator<StrictEffect> {
    yield* takeLatest(postOrder.type, handlePostOrder)
    yield* takeLatest(postOrderPrefly.type, handlePostOrderPrefly)
    yield* takeLatest(revokeOrder.type, handleRevokeOrder)
    yield* takeLatest(acceptedByEmitter.type, handleAcceptedByEmitter)
    yield* takeLatest(refusedByEmitter.type, handleRefusedByEmitter)
    yield* takeLatest(getBuyFee.type, handleLoadBuyFee)
}
