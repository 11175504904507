import { ICalcFeeRequestProps, PostEmissionOrderTypePrefly, UnionOrderType } from '@dltru/dfa-models'
import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
    asset_id: 0,
    asset_ticker_symbol: '',
    asset_title: '',
    created_at: 0,
    executed_at: 0,
    emitter_id: '',
    id: 0,
    price_per_dfa: 0,
    total_price: 0,
    money_lock_id: '',
    uuid: '',
    status: '',
    order_type: '',
    fee: null,
} as UnionOrderType & { fee: number | null; isLoading: boolean }

const orderDetailsSlice = createSlice({
    name: 'orderDetails',
    initialState,
    reducers: {
        updateOrderDetails(state, action) {
            const data = action.payload
            return { ...state, ...data }
        },
        dropOrderDetails() {
            return { ...initialState }
        },
        setOrderError(state, action) {
            state.error = action.payload
        },
        setDfaDetailsFee(state, action) {
            state.fee = action.payload
        },
    },
})
type AcceptOrderType = {
    id: number
    uuid: string
}

export const postOrderPrefly = createAction<PostEmissionOrderTypePrefly>('dfaDetails/postOrderPrefly')
export const postOrder = createAction<PostEmissionOrderTypePrefly>('dfaDetails/postOrder')
export const revokeOrder = createAction<any>('dfaDetails/revokeOrder')
export const acceptedByEmitter = createAction<AcceptOrderType>('dfaDetails/acceptedByEmitter')
export const refusedByEmitter = createAction<AcceptOrderType>('dfaDetails/refusedByEmitter')
export const getBuyFee = createAction<ICalcFeeRequestProps>('dfaDetails/getBuyFee')
export const { updateOrderDetails, dropOrderDetails, setDfaDetailsFee } = orderDetailsSlice.actions
export default orderDetailsSlice.reducer
