import { createSelector } from '@reduxjs/toolkit'

import { EmissionOrderStatus } from '@dltru/dfa-models'

import IAppState from '../../states'

const selectData = (state: IAppState) => state.orderList.data
const selectIsLoading = (state: IAppState) => state.orderList.isLoading

const selectTotalAceptedAmount = createSelector(selectData, (data) => {
    return data.reduce((acc, curr) => {
        if (curr.status === 'accepted' || curr.status === 'accepted_by_emitter') {
            const total = curr.amount_dfa || 0
            acc = acc + total
        }
        return acc
    }, 0)
})

const selectDataByUser = createSelector(
    selectData,
    (_: IAppState, userUuid: string) => userUuid,
    (data, userUuid) => {
        return data.filter((item) => item.user_id === userUuid)
    },
)

const selectHasActiveDataByAssetId = createSelector(
    selectData,
    (_: IAppState, assetId?: number) => assetId,

    (data, assetId) => {
        if (!assetId) {
            return false
        }

        const filtered = data.filter((item) => {
            return (
                item.asset_id === assetId &&
                [EmissionOrderStatus.waiting_for_emitter_accept].includes(
                    item.status as EmissionOrderStatus,
                )
            )
        })

        if (!filtered.length) {
            return false
        }

        return true
    },
)

const isHasAcceptedByEmitter = createSelector(selectData, (deals) =>
    deals.some((deal) => deal.status === EmissionOrderStatus.accepted_by_emitter),
)

const isHasAnyStatusData = createSelector(
    selectData,
    (_: IAppState, assetId?: number) => assetId,

    (data, assetId) => {
        if (!assetId) {
            return false
        }

        const filtered = data.filter((item) => {
            return item.asset_id === assetId
        })

        if (!filtered.length) {
            return false
        }

        return true
    },
)

export const ordersSelector = {
    selectData,
    selectIsLoading,
    selectTotalAceptedAmount,
    selectDataByUser,
    selectHasActiveDataByAssetId,
    isHasAcceptedByEmitter,
    isHasAnyStatusData,
}
